<template>
  <div class="clear-input">
    <input ref="ipt" v-bind="$attrs" v-on="$listeners" :value="value" @input="handleInput" />
    <div class="clear-icon" @click="clearInput">
      <img v-if="value" src="@/assets/imgs/input_clear.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClearInput',
  inheritAttrs: false,
  props: ['value'],
  methods: {
    clearInput() {
      this.$refs.ipt.focus();
      this.$emit('update:value', '');
    },
    handleInput(e) {
      this.$emit('update:value', e.target.value);
      this.$emit('input', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input {
  flex: 1;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  overflow: hidden;
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(151, 152, 171, 1);
  }
}
.clear-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    top: 3px;
  }
}
</style>
