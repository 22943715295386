<template>
  <div>
    <BackBar @click="backBtnClick" title="编辑网点"></BackBar>
    <Bottom :isShowTool="true">
      <template v-slot:footer>
        <div class="detail">
          <div v-show="current === 1" class="step1">
            <div class="step1-btn">
              <div class="withdraw" :class="{ disabled: withdrawDisabled }" @click="withdrawClick">
                <img src="@/assets/imgs/withdraw.png" alt="" />
                <span>撤销</span>
              </div>
              <div class="line"></div>
              <div class="reset" :class="{ disabled: resetDisabled }" @click="resetClick">
                <img src="@/assets/imgs/reset.png" alt="" />
                <span>重置</span>
              </div>
            </div>
            <div class="step1-submit" @click="nextClick">完成</div>
          </div>
          <div v-show="current === 2" class="step2">
            <div class="step2-item park-name">
              <span>名称</span>
              <span>
                <clear-input
                  :value.sync="regionInfo.regionName"
                  maxlength="30"
                  type="text"
                  placeholder="请输入网点名称"
                ></clear-input>
              </span>
            </div>
            <div class="step2-item park-contactName">
              <span>联系人</span>
              <span>
                <clear-input
                  :value.sync="regionInfo.contactName"
                  maxlength="10"
                  type="text"
                  placeholder="请输入联系人"
                ></clear-input>
              </span>
            </div>
            <div class="step2-item park-phone">
              <span>手机号</span>
              <span>
                <clear-input
                  :value.sync="regionInfo.contactPhoneNumber"
                  maxlength="11"
                  type="tel"
                  placeholder="请输入手机号"
                ></clear-input>
              </span>
            </div>
            <div class="step2-item park-company">
              <!-- <span>企业</span> -->
              <div @click="showEnterprisePicker = true">
                <span class="selected" v-if="regionInfo.enterpriseName">{{
                  regionInfo.enterpriseName
                }}</span>
                <span v-else>企业</span>
                <img src="@/assets/imgs/arrRight.png" alt="" />
              </div>
              <div @click="showOperationPicker = true">
                <span class="selected" v-if="regionInfo.adOperatingAreaId">{{
                  regionInfo.adOperatingAreaName
                }}</span>
                <span v-else>运营区域（选填）</span>
                <div
                  v-if="regionInfo.adOperatingAreaId"
                  class="clear-icon"
                  @click.stop="clearOperationId"
                >
                  <img src="@/assets/imgs/input-clear.png" alt="" />
                </div>
                <img src="@/assets/imgs/arrRight.png" alt="" />
              </div>
            </div>
            <div class="step2-btnGroup">
              <div class="prev-btn" @click="prevClick">上一步</div>
              <div class="step2-submit" @click="nextClick" :class="{ disabled: !isComplete }">
                完成设置
              </div>
            </div>
          </div>
          <van-popup v-model="showEnterprisePicker" round position="bottom">
            <van-picker
              v-if="showEnterprisePicker"
              title="企业"
              show-toolbar
              value-key="name"
              :default-index="enterpriseIndex"
              :columns="enterpriseColumns"
              @cancel="showEnterprisePicker = false"
              @confirm="onEnterpriseConfirm"
            />
          </van-popup>
          <van-popup v-model="showOperationPicker" round position="bottom">
            <van-picker
              v-if="showOperationPicker"
              title="运营区域"
              show-toolbar
              :default-index="operationIndex"
              :columns="operationColumns"
              value-key="name"
              @cancel="showOperationPicker = false"
              @confirm="onOperationConfirm"
            />
          </van-popup>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import BackBar from '@/views/LogisticsMap/components/common/BackBar.vue';
import NDialog from '@/components/NDialog/index';
import { defaultPolygonConfig, defaultPolygonEditConfig } from '@/utils/constantMap';
import { aoiEnterpriseList, getAdOperatingArea, appSaveOrUpdateAOI } from '@/api/api';
import bus from '@/utils/bus';
import ClearInput from '../common/ClearInput.vue';

const nameRule = /^[\u4E00-\u9FA5a-zA-Z0-9-]{0,30}$/;

const contactNameRule = /^[\u4E00-\u9FA5a-zA-Z0-9-]{0,10}$/;

const contactPhoneNumberRule = /^1[3-9]\d{9}$/;

export default {
  components: {
    Bottom,
    BackBar,
    ClearInput,
  },
  props: {
    parkOverlayInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      current: 1, // 当前步骤
      queue: [], // 编辑队列
      regionInfo: {
        regionName: null,
        contactName: null,
        contactPhoneNumber: null,
        enterpriseCode: null,
        enterpriseName: null,
        adOperatingAreaId: null,
        adOperatingAreaName: null,
        centerPoint: null,
        pro: null,
        city: null,
        address: null,
        regionFenceList: null,
        fenceArea: null,
      },
      showEnterprisePicker: false,
      showOperationPicker: false,
      enterpriseIndex: null,
      operationIndex: null,
      enterpriseColumns: [],
      operationColumns: [],
    };
  },
  computed: {
    withdrawDisabled() {
      return this.queue.length <= 1;
    },
    resetDisabled() {
      return this.queue.length <= 1;
    },
    isComplete() {
      return (
        this.regionInfo.regionName &&
        this.regionInfo.enterpriseCode &&
        this.regionInfo.contactName &&
        this.regionInfo.contactPhoneNumber
      );
    },
  },
  methods: {
    async backBtnClick() {
      const result = await NDialog.confirm({
        title: '确定取消绘制吗?',
        message: '确定后,刚才绘制和填写的数据均不会保存,请谨慎操作!',
      });
      if (result === 'confirm') {
        this.backClick();
      }
    },
    startEditPolygon() {
      this.$options.polygonEditor.close();
      this.$options.polygonEditor.setTarget(this.$options.polygon);
      this.$options.polygonEditor.open();
    },

    adjustPolygon({ target: polygon }) {
      const path = polygon.getPath();
      this.queue.push(path);
    },

    removePolygonNode({ target: polygon }) {
      const path = polygon.getPath();
      if (path.length < 3) {
        this.$toast('端点不可少于三个');
      }
      // // 删除空了就重新画一个
      if (path.length === 0) {
        // this.$options.map.remove(this.$options.polygon);
        this.$options.polygon?.destroy();
        this.drawCopiedPolygon();
        this.initPolygonEditor();
        this.startEditPolygon();
      } else {
        this.queue.push(path);
      }
    },

    initPolygonEditor() {
      this.$options.polygonEditor = new window.AMap.PolygonEditor(
        this.$options.map,
        this.$options.polygon,
        defaultPolygonEditConfig
      );
      this.$options.polygonEditor.on('addnode', this.adjustPolygon);
      this.$options.polygonEditor.on('removenode', this.removePolygonNode);
      this.$options.polygonEditor.on('adjust', this.adjustPolygon);
    },
    // 回填数据
    initData() {
      const {
        name: regionName,
        contactName,
        contactPhoneNumber,
        adOperatingAreaId,
        adOperatingAreaName,
        enterpriseCode,
        enterpriseName,
      } = this.parkOverlayInfo;
      Object.assign(this.regionInfo, {
        regionName,
        contactName,
        contactPhoneNumber,
        enterpriseCode,
        enterpriseName,
        adOperatingAreaId,
        adOperatingAreaName,
      });
    },

    // 根据存在的多边形，画一个新的多边形
    drawCopiedPolygon() {
      const map = this.$options.map;
      const { regionFenceList, lineRgb, fenceRgb, fenceTransparency } =
        this.parkOverlayInfo?.mapData ?? {};
      const path = [...regionFenceList];
      path.pop();
      const polygon = (this.$options.polygon = new window.AMap.Polygon({
        path,
        ...defaultPolygonConfig,
        strokeColor: lineRgb || '#E89B00',
        fillColor: fenceRgb || '#FFEB3B',
        fillOpacity: fenceTransparency || 0.3,
        zIndex: 1000,
      }));

      // // 添加到地图上
      map.add(polygon);
      this.queue = [path];
    },

    backClick() {
      this.$options.polygonEditor.close();
      this.$options.polygonEditor.clearEvents();
      this.$options.polygon?.destroy();
      this.$store.commit('updateDrawStatus', 0);
      bus.emit('onChangeOverlayOptions', { common: { bubble: false }, zIndex: 1000 });
      bus.emit('cancelEditPark');
    },

    getAdOperatingList(city) {
      getAdOperatingArea({ city }).then((res) => {
        this.operationColumns = res ?? [];
        if (this.regionInfo.adOperatingAreaId) {
          this.operationIndex = this.operationColumns.findIndex(
            (item) => item.id === this.regionInfo.adOperatingAreaId
          );
        }
      });
    },

    async getPolygonCenter() {
      const path = this.$options.polygon.getPath();
      const bounds = this.$options.polygon.getBounds();
      const center = bounds.getCenter();
      const res = await this.getAMapAddress(center);
      this.regionInfo.centerPoint = center.toArray();
      this.regionInfo.fenceArea = this.$options.polygon.getArea();
      this.regionInfo.regionFenceList = [...path, path[0]];
      this.regionInfo.pro = res.addressComponent.province;
      this.regionInfo.city = res.addressComponent.city || res.addressComponent.province;
      this.regionInfo.address = res.aois?.[0]?.name ?? res.formattedAddress;
      // 根据中心点城市，获取一下运营区域列表
      this.getAdOperatingList(this.regionInfo.city);
    },

    withdrawClick() {
      if (this.withdrawDisabled) return;

      this.queue.pop();
      this.$options.polygon.setPath(this.queue.at(-1));
      this.startEditPolygon();
    },

    clearOperationId() {
      this.regionInfo.adOperatingAreaId = null;
      this.regionInfo.adOperatingAreaName = null;
      this.operationIndex = null;
    },
    // 重置
    resetClick() {
      if (this.resetDisabled) return;
      // 将多边形恢复到初始状态
      const path = this.queue.at(0);
      this.$options.polygon.setPath(path);
      this.startEditPolygon();
      this.queue = [path];
    },
    prevClick() {
      this.current = 1;
      this.startEditPolygon();
    },

    async submit() {
      const {
        contactPhoneNumber,
        contactName,
        // pro,
        // city,
        centerPoint,
        regionName,
        enterpriseCode,
        enterpriseName,
        address,
        fenceArea,
        regionFenceList,
        adOperatingAreaId,
        adOperatingAreaName,
      } = this.regionInfo;

      const {
        id,
        parkCode,
        mapData: { fromId, mapId },
      } = this.parkOverlayInfo;

      if (!this.isComplete) return;
      if (!nameRule.test(regionName)) {
        this.$toast('请不要使用特殊符号或表情');
        return;
      }
      if (!contactPhoneNumberRule.test(contactPhoneNumber)) {
        this.$toast('请输入正确的手机号');
        return;
      }
      if (!contactNameRule.test(contactName)) {
        this.$toast('请输入正确的姓名');
        return;
      }
      const result = await NDialog.confirm({ message: '确定提交吗？' });

      if (result !== 'confirm') return;
      this.$loadingCircle.start();
      const params = {
        id,
        fromId,
        // pro,
        // city,
        parkCode,
        mapId,
        regionName,
        fenceArea,
        dataType: '2',
        centerPoint,
        centerPointPoi: centerPoint.join(','),
        fenceRgb: defaultPolygonConfig.fillColor,
        lineRgb: defaultPolygonConfig.strokeColor,
        fenceTransparency: defaultPolygonConfig.fillOpacity,
        regionFenceList,
        nameRemark: '',
        aoiType: '1',
        address,
        deptCode: '',
        head: '',
        headTel: '',
        attribute: 1, // 运营
        enterpriseCode,
        enterpriseName,
        contactName,
        contactPhoneNumber,
        adOperatingAreaId,
        adOperatingAreaName,
      };
      const res = await appSaveOrUpdateAOI(params).finally(() => {
        this.$loadingCircle.end();
      });
      if (res && res.code === '10000') {
        this.$toast('操作成功');
        const mapData = {
          lineRgb: params.lineRgb,
          fenceRgb: params.fenceRgb,
          fenceTransparency: params.fenceTransparency,
          regionFenceList: params.regionFenceList,
          mapId: res.data.mapData.mapId,
          sortIndex: 100,
        };
        bus.emit('onAddNewPark', { ...res.data, isEditing: true, mapData });
        this.backClick();
      } else {
        this.$toast(res.msg);
      }
    },

    nextClick() {
      if (this.current === 1) {
        this.current = 2;
        this.$options.polygonEditor.close();
        this.getPolygonCenter();
      } else {
        this.submit();
      }
    },

    getAoiEnterpriseList() {
      aoiEnterpriseList().then((res) => {
        this.enterpriseColumns = res;
        if (this.regionInfo.enterpriseCode) {
          this.enterpriseIndex = this.enterpriseColumns.findIndex(
            (item) => item.code === this.regionInfo.enterpriseCode
          );
        }
      });
    },
    onEnterpriseConfirm(item, index) {
      this.regionInfo.enterpriseCode = item.code;
      this.regionInfo.enterpriseName = item.name;
      this.enterpriseIndex = index;
      this.showEnterprisePicker = false;
    },

    onOperationConfirm(item, index) {
      this.regionInfo.adOperatingAreaId = item.id;
      this.operationIndex = index;
      this.regionInfo.adOperatingAreaName = item.name;
      this.showOperationPicker = false;
    },
    getAMapAddress(lnglat) {
      return new Promise((resolve, reject) => {
        this.$options.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.regeocode) {
            resolve(result.regeocode);
          } else {
            reject();
          }
        });
      });
    },
  },
  mounted() {
    this.$options.map = window.AMap.lcs.map;
    this.$options.geoCoder = new window.AMap.Geocoder({
      radius: 200,
      batch: false,
      extensions: 'all',
    }); // 初始化地理编码器，查询地址时需要
    this.initData();
    this.drawCopiedPolygon(); // 初始化一个正方形
    this.initPolygonEditor();
    this.startEditPolygon();
    this.getAoiEnterpriseList();
  },
};
</script>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.05);
}
.step1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  opacity: 1;
  border-radius: 16px 16px, 0px, 0px;
  .step1-btn {
    display: flex;
    align-items: center;
    .withdraw,
    .reset {
      display: flex;
      align-items: center;
      &.disabled {
        opacity: 0.3;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 39.2px;
        color: rgba(0, 0, 0, 1);
      }
    }
    .line {
      margin: 0 14px;
      width: 1px;
      height: 14px;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .step1-submit {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 34px;
    padding: 0 29px;
    border-radius: 79px;
    color: rgba(255, 255, 255, 1);
    background: rgba(70, 95, 253, 1);
    &:active {
      transform: scale(0.95);
    }
  }
}

.step2 {
  &-item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    &.park-name {
      padding-top: 8px;
    }

    > span {
      &:nth-child(1) {
        width: 80px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(151, 152, 171, 1);
      }
      &:nth-child(2) {
        position: relative;
        flex: 1;
        input {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0px;
          color: rgba(0, 0, 0, 1);
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(151, 152, 171, 1);
          }
        }
        img {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }

    &.park-company {
      // gap: 12px;

      > div {
        flex: 1 0 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        span {
          flex: 1;
          gap: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(151, 152, 171, 1);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &.selected {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
          }
        }
        .clear-icon {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  &-btnGroup {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .prev-btn {
      flex-grow: 1;
      box-sizing: border-box;
      height: 46px;
      opacity: 1;
      border-radius: 23px;
      border: 2px solid rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(70, 95, 253, 1);
      text-align: center;
    }

    .step2-submit {
      flex-grow: 2;
      box-sizing: border-box;
      height: 46px;
      opacity: 1;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      &.disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>
