<template>
  <div v-if="drawStatus === 2">
    <BackBar @click="backClick" title="规划线路"></BackBar>

    <Bottom :isShowTool="true">
      <template v-slot:footer>
        <div class="add-station">
          <StepOne
            v-show="step === 1"
            :routeDetailInfo="routeDetailInfo"
            :stationList="stationList"
            :isStationPointChange="isStationPointChange"
            @onChangeStaion="handleChangeStaion"
            @onChangeStep="handleChangeStep"
          ></StepOne>
          <StepTwo
            v-show="step === 2"
            :routeDetailInfo="routeDetailInfo"
            :withdrawDisabled="drawHistoryPath.length === 1"
            :isAdjustRoute="isAdjustRoute"
            :drivingResult="drivingResult"
            @onRevoke="handlerRevoke"
            @onChangeStep="handleChangeStep"
            @onChangeNavigationMode="handleChangeNavigationMode"
            @onChangeDringNavigationModeRouteType="handleChangeDringNavigationModeRouteType"
            @onChangeAccuracyType="handleChangeAccuracyType"
          ></StepTwo>
          <StepThree
            ref="routeStepThreeRef"
            v-show="step === 3"
            :step="step"
            :routeDetailInfo="routeDetailInfo"
            :routePath="drawHistoryPath.at(-1)"
            @onChangeStep="handleChangeStep"
          ></StepThree>
          <StepFour
            v-show="step === 4"
            :routeDetailInfo="routeDetailInfo"
            :routeDistance="distanceGaudRoute"
            @onChangeName="onChangeName"
            @onChangeStep="handleChangeStep"
            @onCompleteClick="handleCompleteClick"
          ></StepFour>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import NDialog from '@/components/NDialog'; // 支持promise的dialog
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import StepOne from './components/StepOne.vue';
import StepTwo from './components/StepTwo.vue';
import StepThree from './components/StepThree.vue';
import StepFour from './components/StepFour.vue';
import BackBar from '../common/BackBar.vue';
import { getAuthStationList, saveRoute, generateRouteCode } from '@/api/api';
import { zIndexMax } from '@/utils/constantMap';
let map,
  drawPolyline, // 在手动绘线过程中生成的线,作用是实现鼠标移入/移出弹窗的时候,实现连贯效果
  polylineEditor, //编辑线的实例
  motorwayLinesList = [], // 高精线路的第四步渲染机动车道
  isJustGetInfo = false; // 判断是否是只获取时间和途径路线,编辑的时候兼容老数据

const polylineConfig = {
  strokeWeight: 6, //线的宽度
  polylineStrokeColor: '#FABEC4', //线颜色
  polylineStrokeHighColor: '#FF391B', //线高亮颜色
  motorwaysStrokeColor: '#EDE574', // 机动车道线颜色
  motorwaysStrokeHighColor: '#FFC300', // 机动车道线高亮颜色
};
// 线编辑的默认属性
const editPolylineConfig = {
  editOptions: {
    // 绘制线的属性
    strokeColor: '#FF1132',
    strokeOpacity: 1,
    strokeWeight: 6,
    strokeStyle: 'solid',
  },
  controlPoint: {
    // 顶点属性
    strokeColor: 'rgba(0, 13, 255, 1)',
    strokeOpacity: 1,
    radius: 5,
  },
  midControlPoint: {
    strokeColor: 'rgba(0, 13, 255, 1)',
    fillColor: 'rgba(0, 13, 255, 1)',
    strokeOpacity: 1,
    radius: 5,
  },
};
export default {
  components: { BackBar, StepOne, StepTwo, StepThree, StepFour, Bottom },
  props: [],
  data() {
    return {
      step: 1,
      isEditing: false, //判断当前是新增还是编辑路线
      stationList: [], // 当前网格下的站点列表
      routeDetailInfo: {}, // 当前路线
      dayNight: '0', //  0昼行,1夜行
      navigationMode: '2', //1 骑行,2驾驶,
      drawOriginPath: [], // 记录在编辑前的当前节点信息,方便重新绘制
      drawHistoryPath: [], // 绘制的时候二维数组记录绘制的每一步的节点,方便撤销;编辑的时候,记录每一次更新时的节点
      navigationModeRouteType: '0', // 策略
      routeType: '1', // 1去程 2返程 0单程闭环
      accuracyType: 1, // 1普通,2高精
      routeDuration: 0, //距离
      drivingResult: [], // 驾车规划结果
      isAdjustRoute: false, // 是否调整路线,如果调整路线了,修改路线生成方案的时候给予提示
      isEditingRoute: false, //路线绘制是否是编辑态
      withdrawDisabled: false, //撤销到最后不可撤销
      pilotSubWayRoutePathsTemp: [], // 记录 站点和途经点的数据,如果有变化,路线重新生成
      vehicleLaneLine: [], // 机动路线数组
      amapPassWay: '', // 途径
      isChangeName: false, // 记录新建的时候是否修改过名字
    };
  },
  computed: {
    ...mapGetters({
      parkInfo: 'getParkInfo',
      drawStatus: 'getDrawStatus',
    }),
    stationIds() {
      let idsArr = [];
      if (this.routeDetailInfo?.pilotSubWayRoutePaths) {
        for (const station of this.routeDetailInfo?.pilotSubWayRoutePaths) {
          if (station.id) {
            idsArr.push(station.id);
          } else {
            idsArr.push(null);
          }
        }
      }
      return idsArr.join(',');
    },
    // 站点坐标集合,用于请求高度路线
    stationCoordinateArr() {
      let temp = [];
      this.routeDetailInfo.pilotSubWayRoutePaths.forEach((item) => {
        if (item.highPrecision === 1) {
          if (item.originalCoordinates) {
            let coordinatesArr = item.originalCoordinates.split(',');
            temp.push(coordinatesArr);
          }
        } else {
          temp.push([item.lng, item.lat]);
        }
      });
      return temp;
    },
    // 站点是否改变
    isStationPointChange() {
      return !(
        this.pilotSubWayRoutePathsTemp.flat(1).join(',') ===
        this.stationCoordinateArr.flat(1).join(',')
      );
    },
    // 高德路线的距离
    distanceGaudRoute() {
      if (this.drawHistoryPath.length > 0) {
        return Math.round(AMap.GeometryUtil.distanceOfLine(this.drawHistoryPath.at(-1)));
      }
      return 0;
    },
  },
  watch: {
    stationIds: {
      handler: function () {
        bus.emit('onChangeStationBase', this.stationIds);
      },
      immediate: false,
      deep: true,
    },
  },
  methods: {
    // 取消绘制
    async backClick(type) {
      if (type) {
        this.resetProperties();
      } else {
        const result = await NDialog.confirm({
          title: '确定取消绘制吗？',
          message: '确定后，刚才绘制和填写的数据均不会保存，请谨慎操作！',
        });
        if (result === 'confirm') {
          this.resetProperties();
        }
      }
    },
    // 退出时重置属性
    resetProperties() {
      this.step = 1;
      this.routeDetailInfo = {};
      polylineEditor && polylineEditor.close();
      drawPolyline && drawPolyline.remove();
      this.drawHistoryPath = [];
      this.drawOriginPath = [];
      this.isAdjustRoute = false;
      this.navigationMode = '2';
      this.navigationModeRouteType = '0';
      this.routeType = '1';
      this.drivingResult = [];
      this.isEditing = false;
      this.isEditingRoute = false;
      this.pilotSubWayRoutePathsTemp = [];
      this.routeDuration = 0;
      this.accuracyType = 1;
      bus.emit('onIshowMarkerOverlay', true);
      bus.emit('onIshowPolylineOverlayGroup', true);
      this.$store.commit('updateDrawStatus', 0);
      bus.emit('onChangeOverlayOptions', { common: { bubble: false }, zIndex: 1000 });
      this.$refs.routeStepThreeRef.clearMotorwayLines();
      if (motorwayLinesList.length > 0) {
        for (const motorwayArr of motorwayLinesList) {
          motorwayArr.forEach((motorway) => motorway.remove());
        }
        motorwayLinesList = [];
      }
      bus.emit('onIshowSpecialMarkerOverlay', true);
      isJustGetInfo = false;
      this.amapPassWay = '';
    },
    // 新增路线
    addDrawRoute() {
      map = this.$parent.$parent.$options.map;
      this.$set(this.routeDetailInfo, 'pilotSubWayRoutePaths', [{}, {}]);
      this.getAuthStationListServer(this.parkInfo.parkCode);
    },
    // 编辑路线
    editDrawRoute(payload) {
      map = this.$parent.$parent.$options.map;
      this.isEditing = true;
      this.routeDetailInfo = payload;
      if (payload.vehicleLaneLine) {
        this.routeDetailInfo.vehicleLaneLine = JSON.parse(payload.vehicleLaneLine);
      }
      this.getAuthStationListServer(payload.parkCode);
      this.pilotSubWayRoutePathsTemp = [...this.stationCoordinateArr];
      this.drawHistoryPath = [payload.pilotSubWayCoordinates];
      this.drawOriginPath = payload.pilotSubWayCoordinates;
      this.isAdjustRoute = payload.isAdjustRoute === 1 ? true : false;
      if (payload.highPrecision === 1) {
        this.step = 3;
        setTimeout(() => {
          this.step = 4;
          this.drawLine();
          this.renderHighRouteMotorwayLines();
        }, 0);
      }
      // 兼容1.0的老数据
      const navigationModeRouteTypeTemp = this.routeDetailInfo.navigationModeRouteType;
      if (navigationModeRouteTypeTemp) {
        if (navigationModeRouteTypeTemp === 'LEAST_TIME') {
          this.routeDetailInfo.navigationModeRouteType = '0';
        } else if (navigationModeRouteTypeTemp === 'LEAST_FEE') {
          this.routeDetailInfo.navigationModeRouteType = '1';
        } else if (navigationModeRouteTypeTemp === 'LEAST_DISTANCE') {
          this.routeDetailInfo.navigationModeRouteType = '2';
        }
      }
      this.navigationMode = String(this.routeDetailInfo.navigationMode);
      this.navigationModeRouteType = this.routeDetailInfo.navigationModeRouteType;
      this.routeType = this.routeDetailInfo.routeType;
      this.accuracyType = this.routeDetailInfo.accuracyType;
      this.amapPassWay = this.routeDetailInfo.amapPassWay;
      if (this.navigationMode === '2') {
        isJustGetInfo = true;
        this.buildGaudRoute(this.stationCoordinateArr);
      }
    },
    async getAuthStationListServer(parkCode) {
      const res = await getAuthStationList({
        parkCode: parkCode,
        showMarkPoint: true, // 包含标注数据
      });
      this.stationList = res;
    },
    handleChangeStep({ step, vehicleLaneLine }) {
      this.step = step;
      if (step === 1) {
        !this.isEditing && bus.emit('onIshowMarkerOverlay', true);
        bus.emit('onChangeOverlayOptions', { common: { bubble: true }, zIndex: 1000 });
        // 选择站点
        polylineEditor?.close();
        drawPolyline?.hide();
      } else if (step === 2) {
        this.handleBuildRoute();
        bus.emit('onChangeOverlayOptions', { common: { bubble: false }, zIndex: 1 });
        bus.emit('onIshowMarkerOverlay', false);
      } else if (step === 3) {
        this.$refs.routeStepThreeRef.drawMotorwaysMarkerOverlayGroup();
        this.$refs.routeStepThreeRef.updateMotorwayLines();
        this.$refs.routeStepThreeRef.hideOrShowMotorwayLines(true);
        polylineEditor?.close();
        bus.emit('onChangeOverlayOptions', { common: { bubble: false }, zIndex: 1000 });
      } else if (step === 4) {
        if (!this.isChangeName && !this.isEditing) {
          const startStation = this.routeDetailInfo.pilotSubWayRoutePaths.at(0);
          const endStation = this.routeDetailInfo.pilotSubWayRoutePaths.at(-1);
          const subwayName = `${startStation.stationName}-${endStation.stationName}`;
          this.$set(this.routeDetailInfo, 'subwayName', subwayName);
        }
        this.vehicleLaneLine = vehicleLaneLine;
        bus.emit('onIshowMarkerOverlay', false);
      }
    },
    /************第一步 start*****************/
    handleChangeStaion({ index, station }) {
      let pilotSubWayRoutePaths = this.routeDetailInfo.pilotSubWayRoutePaths;
      pilotSubWayRoutePaths[index] = { ...station, index };
      this.navigationModeRouteType = '0';
      this.routeDetailInfo.navigationModeRouteType = '0';
      this.$set(this.routeDetailInfo, 'pilotSubWayRoutePaths', [...pilotSubWayRoutePaths]);
    },
    // 路线第一步,点击地图上的Marker图标
    handleMarkerDetailInfo(payload) {
      if (this.step !== 1) return;
      if (
        payload.highPrecision === 1 &&
        !payload.originalCoordinates &&
        this.routeDetailInfo?.pilotSubWayRoutePaths.some((item) => !item.id)
      ) {
        this.$toast('当前坐标数据异常,请重新修改数据');
        return;
      }
      payload.stationName = payload.name;
      const idx = this.routeDetailInfo.pilotSubWayRoutePaths.findIndex(
        (item) => item.id === payload.id
      );
      if (~idx) {
        let tempArr = [];
        this.routeDetailInfo.pilotSubWayRoutePaths.forEach((station, idx) => {
          if (station.id === payload.id) {
            tempArr.push({});
          } else {
            tempArr.push(station);
          }
        });
        this.$set(this.routeDetailInfo, 'pilotSubWayRoutePaths', tempArr);
      } else {
        for (let i = 0; i < this.routeDetailInfo.pilotSubWayRoutePaths.length; i++) {
          const station = this.routeDetailInfo.pilotSubWayRoutePaths[i];
          if (!station.id) {
            this.handleChangeStaion({ index: i, station: payload });
            break;
          }
        }
      }
    },
    handleBuildRoute() {
      bus.emit('onChangeOverlayOptions', { common: { bubble: true }, zIndex: 1 });
      // 站点是否发生变化,发生变化重新生成,否则不用重新生成路线
      if (this.isStationPointChange) {
        this.pilotSubWayRoutePathsTemp = [...this.stationCoordinateArr];
        this.isAdjustRoute = false;
        if (this.navigationMode === '2') {
          this.drivingSearch();
        } else if (this.navigationMode === '1') {
          const riding = new AMap.Riding();
          this.ridingSearch(this.stationCoordinateArr, riding);
        }
      } else {
        this.drawLine();
        setTimeout(() => {
          this.polylineEditorFnc({ target: drawPolyline });
        }, 10);
      }
    },

    drawDrivingRoute() {
      const firstRoute = this.drivingResult[this.navigationModeRouteType];
      let lnglatArr = [],
        roadNameArr = [];
      if (this.accuracyType === 1) {
        // 普通精度
        firstRoute.steps.forEach((item) => {
          const lanlat = item.start_location;
          lnglatArr.push([lanlat.getLng(), lanlat.getLat()]);
          const lanlatEnd = item.end_location;
          lnglatArr.push([lanlatEnd.getLng(), lanlatEnd.getLat()]);
          item.road && roadNameArr.push(item.road);
        });
      } else if (this.accuracyType === 2) {
        // 提升精度
        firstRoute.steps.forEach((item) => {
          item.path.forEach((lanlat) => {
            lnglatArr.push([lanlat.getLng(), lanlat.getLat()]);
          });
          item.road && roadNameArr.push(item.road);
        });
      }
      if (!isJustGetInfo) {
        const ridingNodes = [...lnglatArr];
        this.drawHistoryPath = [ridingNodes];
        this.drawOriginPath = ridingNodes;
        this.drawLine();
        setTimeout(() => {
          this.polylineEditorFnc({ target: drawPolyline });
        }, 10);
        this.$toast('已自动生成线路');
      }
      this.amapPassWay = [...new Set(roadNameArr)].join('，');
      this.routeDuration = firstRoute.time;
      isJustGetInfo = false;
    },
    drawLine() {
      // 删除上一次的折线,重新绘制
      drawPolyline && map.remove(drawPolyline);
      setTimeout(() => {
        let path = this.drawHistoryPath.at(-1);
        drawPolyline = new AMap.Polyline({
          path: path,
          cursor: 'pointer',
          showDir: true,
          strokeColor: polylineConfig.polylineStrokeHighColor,
          strokeWeight: polylineConfig.strokeWeight,
          strokeOpacity: 1,
          bubble: false,
        });
        map.add(drawPolyline);
      }, 0);
    },
    // 触发线的编辑器,开始编辑
    polylineEditorFnc({ target: Polyline }) {
      polylineEditor?.close();
      this.isEditingRoute = true;
      // 实例化多边形编辑器，传入地图实例和要进行编辑的多边形实例
      polylineEditor = new AMap.PolylineEditor(map, Polyline, editPolylineConfig);
      // 开启编辑模式
      polylineEditor.open();
      polylineEditor.on('addnode', this.updatePathArr);
      polylineEditor.on('removenode', this.removenode);
      polylineEditor.on('adjust', this.updatePathArr);
      polylineEditor.on('end', ({ target: Polyline }) => {
        this.isEditingRoute = false;
      });
    },
    updatePathArr({ target: Polyline }) {
      const newPath = Polyline.getPath();
      const lnglatArr = newPath.map((lnglat) => {
        return [lnglat.getLng(), lnglat.getLat()];
      });
      this.drawHistoryPath.push(lnglatArr);
      this.isAdjustRoute = true;
    },
    removenode({ target: Polyline }) {
      const newPath = Polyline.getPath();
      if (this.drawHistoryPath.at(-1).length === 2 && newPath.length === 2) {
        // 点删除完,设置重新绘制状态
        this.handlerRedraw();
        return;
      }
      const lnglatArr = newPath.map((lnglat) => {
        return [lnglat.getLng(), lnglat.getLat()];
      });
      this.drawHistoryPath.push(lnglatArr);
      this.isAdjustRoute = true;
    },
    // 重新绘制
    handlerRedraw() {
      this.drawHistoryPath = [this.drawOriginPath];
      polylineEditor.close();
      drawPolyline.setPath(this.drawOriginPath);
      polylineEditor.open();
      this.isAdjustRoute = false;
    },
    //驾车
    drivingSearch() {
      let driving = new AMap.Driving({ policy: 10 });
      let waypoints = [];
      if (this.stationCoordinateArr.length > 2) {
        waypoints = this.stationCoordinateArr.slice(1, this.stationCoordinateArr.length - 1);
      }
      const _this = this;
      // 根据起终点经纬度规划驾车导航路线
      driving.search(
        this.stationCoordinateArr[0],
        this.stationCoordinateArr.at(-1),
        {
          waypoints,
        },
        function (status, result) {
          if (status === 'complete') {
            _this.drivingResult = result.routes;
            _this.drawDrivingRoute();
          } else {
            if (!isJustGetInfo) {
              _this.drawHistoryPath = [_this.stationCoordinateArr];
              _this.drawOriginPath = _this.stationCoordinateArr;
              _this.drawLine();
              setTimeout(() => {
                _this.polylineEditorFnc({ target: drawPolyline });
              }, 10);
              this.$toast('请手动调整线路');
            }
            _this.amapPassWay = '';
            isJustGetInfo = false;
            _this.routeDuration = 0;
          }
        }
      );
    },
    // 骑行
    ridingSearch(stationArr, riding, index = 0, routeArr = [], times = 0, roadNameList = []) {
      // 默认推荐路线及最快路线综合
      let _this = this;
      riding.search(stationArr[index], stationArr[index + 1], (status, result) => {
        //status为complete时，result为DrivingResult；当status为error时，result为错误信息info；当status为no_data时，代表检索返回0结果
        if (status === 'complete') {
          const firstRoute = result.routes[0];
          let lnglatArr = [],
            roadNameArr = [];
          if (_this.accuracyType === 1) {
            // 普通精度
            lnglatArr = firstRoute.rides.map((item) => {
              const lanlat = item.start_location;
              item.road && roadNameArr.push(item.road);
              return [lanlat.getLng(), lanlat.getLat()];
            });
          } else if (_this.accuracyType === 2) {
            // 提升精度
            firstRoute.rides.forEach((item) => {
              item.path.forEach((lanlat) => {
                lnglatArr.push([lanlat.getLng(), lanlat.getLat()]);
              });
              item.road && roadNameArr.push(item.road);
            });
          }
          times += firstRoute.time;
          roadNameList = [...roadNameList, ...roadNameArr];
          routeArr = routeArr.concat([stationArr[index], ...lnglatArr]);
          ++index;
          if (index === stationArr.length - 1) {
            if (!isJustGetInfo) {
              // 获取规划路线的节点,并在地图显示,为了绘制精确,把起始点的坐标加上
              const ridingNodes = [...routeArr, stationArr.at(-1)];
              _this.drawHistoryPath = [ridingNodes];
              _this.drawOriginPath = ridingNodes;
              _this.drawLine();
              setTimeout(() => {
                _this.polylineEditorFnc({ target: drawPolyline });
              }, 10);
              _this.$toast('已自动生成线路');
            }
            this.amapPassWay = [...new Set(roadNameList)].join('，');
            this.routeDuration = times;
            isJustGetInfo = false;
            return;
          }
          _this.ridingSearch(stationArr, riding, index, routeArr, times, roadNameList);
        } else if (status === 'no_data' || status === 'error') {
          if (!isJustGetInfo) {
            _this.drawHistoryPath = [stationArr];
            _this.drawOriginPath = stationArr;
            _this.drawLine();
            setTimeout(() => {
              _this.polylineEditorFnc({ target: drawPolyline });
            }, 10);
            _this.$toast('请手动调整线路');
          }
          _this.routeDuration = 0;
          _this.amapPassWay = '';
          isJustGetInfo = false;
        }
      });
    },
    /************第一步 end*****************/
    /************第二步 start*****************/
    // 撤销上一步
    handlerRevoke() {
      this.drawHistoryPath.pop();
      polylineEditor.close();
      drawPolyline.setPath(this.drawHistoryPath.at(-1));
      polylineEditor.open();
      if (this.drawHistoryPath.length === 1) {
        this.isAdjustRoute = false;
      }
    },

    // 修改机动和非机动方式
    handleChangeNavigationMode(payload) {
      const {
        navigationMode: navigationModeTemp,
        navigationModeRouteType: navigationModeRouteTypeTemp,
      } = payload;
      this.navigationMode = navigationModeTemp;
      this.navigationModeRouteType = navigationModeRouteTypeTemp;
      this.isAdjustRoute = false;
      this.routeDetailInfo.navigationModeRouteType = navigationModeRouteTypeTemp;
      this.buildGaudRoute(this.stationCoordinateArr);
    },
    // 修改机动车道的线路
    handleChangeDringNavigationModeRouteType(payload) {
      this.navigationModeRouteType = payload.navigationModeRouteType;
      this.routeDetailInfo.navigationModeRouteType = payload.navigationModeRouteType;
      this.isAdjustRoute = false;
      this.drawDrivingRoute();
    },
    //是否提升精度
    handleChangeAccuracyType(accuracyType) {
      this.accuracyType = Number(accuracyType);
      this.polylineEditor?.close();
      this.isAdjustRoute = false;
      this.buildGaudRoute(this.stationCoordinateArr);
    },
    buildGaudRoute(stationArr) {
      if (this.navigationMode === '2') {
        this.drivingSearch(stationArr);
      } else if (this.navigationMode === '1') {
        const riding = new AMap.Riding();
        this.ridingSearch(stationArr, riding);
      }
    },

    /************第二步 end*****************/
    /************第四步 start*****************/

    onChangeName() {
      this.isChangeName = true;
    },
    // 高精线路只有第四步,所以展示机动车道
    async handleCompleteClick({ subwayName, routeType }) {
      this.routeType = routeType;
      this.$loadingCircle.start();
      const pilotSubWayCoordinates = this.drawHistoryPath.at(-1);
      const routeCenter = this.computerRouteInfoWindowPosition(pilotSubWayCoordinates);
      let formatFormState = {};
      if (this.isEditing) {
        formatFormState = { ...this.routeDetailInfo, subwayName, routeType };
        if (formatFormState.highPrecision === 0) {
          formatFormState.pilotSubWayRoutePaths = this.formatPilotSubWayRoutePaths();
          formatFormState.vehicleLaneLine = JSON.stringify(this.vehicleLaneLine);
          formatFormState.accuracyType = this.accuracyType;
          formatFormState.motorwayDistance = this.computerMotorwayDistance();
          formatFormState.pilotSubWayCoordinates = pilotSubWayCoordinates;
          formatFormState.routeDistance = this.distanceGaudRoute;
          formatFormState.lng = routeCenter[0];
          formatFormState.lat = routeCenter[1];
          formatFormState.navigationMode = this.navigationMode;
          formatFormState.navigationModeRouteType = this.navigationModeRouteType;
          formatFormState.amapPassWay = this.amapPassWay;
          formatFormState.isAdjustRoute = this.isAdjustRoute ? 1 : 0;
        } else {
          formatFormState.vehicleLaneLine = JSON.stringify(this.routeDetailInfo.vehicleLaneLine);
        }
      } else {
        const routeCode = await this.getGenerateRouteCode();
        formatFormState = {
          subwayName,
          routeType,
        };
        formatFormState.parkName = this.parkInfo.parkName;
        formatFormState.parkCode = this.parkInfo.parkCode;
        formatFormState.highPrecision = 0;
        formatFormState.pilotMapRouteDTO = null;
        formatFormState.pilotSubWayOperatingShifts = [];
        formatFormState.runTime = 0;
        formatFormState.vehicleLaneLine = JSON.stringify(this.vehicleLaneLine);
        formatFormState.routeCode = routeCode;
        formatFormState.sortPortNum = '001';
        formatFormState.pilotSubWayRoutePaths = this.formatPilotSubWayRoutePaths();
        formatFormState.accuracyType = this.accuracyType;
        formatFormState.syncToAd = true;
        formatFormState.color = '#FF003C';
        formatFormState.dayNight = this.dayNight;
        formatFormState.motorwayDistance = this.computerMotorwayDistance();
        formatFormState.pilotSubWayCoordinates = pilotSubWayCoordinates;
        formatFormState.routeDistance = this.distanceGaudRoute;
        formatFormState.lng = routeCenter[0];
        formatFormState.lat = routeCenter[1];
        formatFormState.navigationMode = this.navigationMode;
        formatFormState.navigationModeRouteType = this.navigationModeRouteType;
        formatFormState.amapPassWay = this.amapPassWay;
        formatFormState.isAdjustRoute = this.isAdjustRoute ? 1 : 0;
      }
      const res = await saveRoute(formatFormState);
      if (res.code === '10000') {
        if (this.isEditing) {
          this.$toast('操作成功');
        } else {
          this.$toast('线路规划成功');
        }
        setTimeout(() => {
          bus.emit('onAddNewRoute', {
            ...res.data,
            routeId: res.data.id,
            isEditing: this.isEditing,
            mapId: this.routeDetailInfo.mapId || '', // 更新时必传
            pilotSubWayCoordinates: pilotSubWayCoordinates,
          });
          this.backClick(1);
          this.$loadingCircle.end();
        }, 10);
      } else {
        this.$toast(res.msg);
      }
    },
    // 获取路线编码
    async getGenerateRouteCode() {
      const startStation = this.routeDetailInfo.pilotSubWayRoutePaths[0];
      const endStation = this.routeDetailInfo.pilotSubWayRoutePaths[1];
      const res = await generateRouteCode({
        startStationId: startStation.id,
        finishStationId: endStation.id,
      });
      return res;
    },
    formatPilotSubWayRoutePaths() {
      let tempArr = [];
      this.routeDetailInfo.pilotSubWayRoutePaths.forEach((item, index) => {
        tempArr.push({
          id: item.id,
          stationId: item.id,
          stationName: item.stationName,
          sort: index + 1,
          routeType: this.routeType,
          mapData: { lng: item.lng, lat: item.lat },
          contactNumber: item.contactNumber,
          routePath: item.routePath || [],
          waitTime: item.waitTime,
        });
      });
      return tempArr;
    },
    // 计算路线中心点
    computerRouteInfoWindowPosition(pilotSubWayCoordinates) {
      let bounds = map.getBounds();
      let mapCenter = map.getCenter();
      const centerIndex = parseInt(pilotSubWayCoordinates.length / 2);
      let miniDistance = null,
        miniLnglat = pilotSubWayCoordinates[centerIndex]; // 默认中间点
      for (let lnglat of pilotSubWayCoordinates) {
        if (bounds.contains(lnglat)) {
          const distance = AMap.GeometryUtil.distance(lnglat, mapCenter);
          if (!miniDistance || (miniDistance && distance < miniDistance)) {
            miniDistance = distance;
            miniLnglat = lnglat;
          }
        }
      }
      return miniLnglat;
    },
    computerMotorwayDistance() {
      if (!this.vehicleLaneLine || this.vehicleLaneLine?.length === 0) return 0;
      let len = 0;
      this.vehicleLaneLine[0].forEach((element) => {
        len += AMap.GeometryUtil.distanceOfLine(element);
      });
      return Math.round(len);
    },
    renderHighRouteMotorwayLines() {
      if (this.routeDetailInfo?.vehicleLaneLine?.length > 0) {
        this.routeDetailInfo.vehicleLaneLine.forEach((oneArr) => {
          let motorwayArr = [];
          oneArr.forEach((motorway) => {
            motorwayArr.push(this.motorwaysLine(motorway));
          });
          motorwayLinesList.push(motorwayArr);
        });
      }
    },
    motorwaysLine(path) {
      const line = new AMap.Polyline({
        path: path,
        cursor: 'pointer',
        showDir: true,
        strokeColor: polylineConfig.motorwaysStrokeHighColor,
        strokeWeight: polylineConfig.strokeWeight + 1,
        strokeOpacity: 1,
        bubble: false,
        extData: { path },
        zIndex: zIndexMax - 100,
      });
      map.add(line);
      return line;
    },
    /************第四步 end*****************/
  },
  mounted() {
    // 从地图点击Marker
    bus.on('onMarkerDetailInfo', this.handleMarkerDetailInfo);
  },
};
</script>
<style scoped lang="scss">
.add-station {
  margin-top: 20px;
}
.backBtn {
  position: absolute;
  top: 5px;
  left: 10px;
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  padding: 12px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
  }
}
</style>
